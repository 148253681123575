<route-meta>
	{
	"requiresAuth": false,
	"title": "Login"
	}
</route-meta>

<template>
	<form @submit.prevent="login" class="p-3 text-center">
		<input type="text" v-model="loginData.username" class="input my-2" name="login" placeholder="login">
		<input type="password" v-model="loginData.password" class="input my-2" name="login" placeholder="password">
		<button :disabled="loading" :class="{loading}" class="btn btn-primary py-3 px-5 my-3 w-100">Login</button>
	</form>
</template>

<script>
	import api from '@/api'

	export default {
		layout: 'empty',
		data() {
			return {
				loginData: {
					username: '',
					password: ''
				},
				loading: false
			}
		},
		methods: {
			async login() {
				this.loading = true
				let response = await api.Auth.login(this.loginData).then(
						async _ => {
							this.loading = false
							this.$router.push({name: 'dashboard-index'})
							await this.$store.dispatch('User/afterLogin')
							await this.$store.dispatch('Projects/load')
						}
				).catch(() => this.loading = false)

			}
		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/style/_palette";

	input.input {
		background-color: $color-grey;
		color: $color-primary;
		padding: 15px 32px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 16px;
		width: 100%;
		border: 2px solid #f6f6f6;
		-webkit-border-radius: 5px 5px 5px 5px;
		border-radius: 5px 5px 5px 5px;

		&:focus {
			background-color: #fff;
			border-bottom: 2px solid $color-primary;
		}

		&::placeholder {
			color: #cccccc;
		}
	}
</style>
